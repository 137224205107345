.inactiveImage {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: brightness(65%);
    transform: scale(1);
}
.activeImage {
    animation: activeImage 1000ms ease forwards;
}
@keyframes activeImage {
    0%{
        filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: brightness(55%);
    transform: scale(1);
    }100%{

    }
}
.descriptionContainer, .aboutContainer{
    scroll-snap-type: x mandatory;
}
.descriptionContainer p{
    scroll-snap-align: center;
}
.aboutContainer img {
    scroll-snap-align: center;
}
.profileImg {
    width: 248px;
    max-width: 248px;
}
.aboutCarouselImg{
    width: 248px;
    max-width: 248px;
}
.aboutCarousel .carousel .control-next.control-arrow:before {
    border: solid #FF4A5C;
    border-width: 0 8px 8px 0;
    right: 20px;
    top: 104px;
    position: absolute;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.aboutCarousel .carousel .control-next.control-arrow{
    right: -10px;
}
.aboutCarousel .carousel .control-prev.control-arrow{
    left: -10px;
}
.aboutCarousel .carousel .control-prev.control-arrow:before {
    border: solid #FF4A5C;
    border-width: 0 8px 8px 0;
    display: inline-block;
    top: 104px;
    left: 20px;
    position: absolute;
    padding: 10px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.carousel.carousel-slider .control-arrow {
    position: absolute;
    top: 40%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
}