.projectAppear {
    animation: projectAppear 1.2s ease forwards;
}
@keyframes projectAppear {
    0%{
        position: absolute;
        top: calc(100vh + var(--index) * 320px);
        z-index: 20;
    }100%{
        position: absolute;
        top: 0px;
        z-index: 10;
    }
}
.projectDisappear {
    animation: projectDisappear 1.5s ease forwards;
    z-index: 1000;
}
@keyframes projectDisappear {
    0%{
        position: absolute;
        top: 80px;
        z-index: 1000;
    }
    100%{
        position: absolute;
        top: calc(100vh + var(--index)* 320px);
        z-index: 100;
    }
}
.projectFadeOut{
    animation: projectFadeOut 2s ease forwards;
}
@keyframes projectFadeOut {
    0%{
        opacity: 1;
        z-index: 0;
        position: absolute;
    }
    50%{
        opacity: 0;
        position: absolute;
        z-index: 0;
    }100%{
        opacity: 0;
        position: absolute;
    }
}
@media (max-width:639px){
    .projectWidth{
        width: '100vw';
    }
    .content{
        animation: fade 1500ms ease;
    }
    .projectAppear {
        animation: projectAppear 1.5s ease forwards;
    }
    @keyframes projectAppear {
        0%{
            position: absolute;
            top: calc(100vh + var(--index) * 520px);
            z-index: 20;
        }100%{
            position: absolute;
            top: 0px;
            z-index: 10;
        }
    }
    .projectDisappear {
        animation: projectDisappear calc(1.5s + 0.5s * var(--index)) ease forwards;
        z-index: 1000;
    }
    @keyframes projectDisappear {
        0%{
            position: absolute;
            top: 80px;
            z-index: 1000;
        }
        100%{
            position: absolute;
            top: calc(100vh + var(--index)* 520px);
            z-index: 100;
        }
    }
}
/* 640PIXELS TO 1024PIXELS MEDIA QUERY ANIMATIONS */
@media (min-width:640px) and (max-width: 1024px) {
    .projectWidth{
        width: '50vw';
    }
    .content{
        animation: fade 1500ms ease;
    }
    .projectAppear {
        animation: projectAppear 1.5s ease forwards;
    }
    @keyframes projectAppear {
        0%{
            position: absolute;
            top: calc(100vh + var(--index) * 640px);
            z-index: 20;
        }100%{
            position: absolute;
            top: 0px;
            z-index: 10;
        }
    }
    .projectDisappear {
        animation: projectDisappear calc(1.5s + 0.5s * var(--index)) ease forwards;
        z-index: 1000;
    }
    @keyframes projectDisappear {
        0%{
            position: absolute;
            top: 80px;
            z-index: 10;
        }
        100%{
            position: absolute;
            top: calc(100vh + var(--index)* 640px);
            z-index: 120;
        }
    }
}
@media (min-width: 1600px) {
    .content{
        animation: fade 1500ms ease;
    }
    @keyframes projectAppear {
        0%{
            position: absolute;
            top: calc(100vh + var(--index) * 320px);
            z-index: 20;
        }100%{
            position: absolute;
            top: 0px;
            z-index: 10;
        }
    }
    .projectDisappear {
        animation: projectDisappear calc(1.2s + 0.5s * var(--index)) ease forwards;
        z-index: 1000;
    }
    @keyframes projectDisappear {
        0%{
            position: absolute;
            top: 80px;
            z-index: 1000;
        }
        100%{
            position: absolute;
            top: calc(100vh + var(--index)* 320px);
            z-index: 100;
        }
    }
}
@media (min-width: 2048px) {
    @keyframes projectAppear {
        0%{
            position: absolute;
            top: calc(100vh + var(--index) * 512px);
            z-index: 20;
        }100%{
            position: absolute;
            top: 0px;
            z-index: 10;
        }
    }
    .projectDisappear {
        animation: projectDisappear calc(1.2s + 0.5s * var(--index)) ease forwards;
        z-index: 1000;
    }
    @keyframes projectDisappear {
        0%{
            position: absolute;
            top: 80px;
            z-index: 1000;
        }
        100%{
            position: absolute;
            top: calc(100vh + var(--index)* 512px);
            z-index: 100;
        }
    }
}
.carousel .slide iframe{
    margin: 0 !important;
    width: 100% !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}
/* .carousel .control-arrow, .carousel.carousel-slider .control-arrow:hover{
    opacity: 1;
} */
.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #FF4A5C;
    border-width: 0 8px 8px 0;
    margin-right: 10px;
    display: inline-block;
    padding: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    /* content: ''; */
    border: solid #FF4A5C;
    border-width: 0 8px 8px 0;
    display: inline-block;
    margin-left: 10px;
    padding: 7px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.projectWidth{
    width: '50vw';
}
@media (min-width: 1024px) {
    #slider > div:nth-child(1),
    #slider > div:nth-child(4),
    #slider > div:nth-child(7),
    #slider > div:nth-child(10),
    #slider > div:nth-child(13),
    #slider > div:nth-child(16)
    {
        margin-left: 120px;
    }
    #slider > div:nth-child(3),
    #slider > div:nth-child(6),
    #slider > div:nth-child(9),
    #slider > div:nth-child(12),
    #slider > div:nth-child(15),
    #slider > div:nth-child(18)
    {
        margin-right: 80px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    #slider > div:nth-child(1),
    #slider > div:nth-child(3),
    #slider > div:nth-child(5),
    #slider > div:nth-child(7),
    #slider >  div:nth-child(9),
    #slider > div:nth-child(11),
    #slider > div:nth-child(13),
    #slider > div:nth-child(15),
    #slider > div:nth-child(17),
    #slider > div:nth-child(19)
    {
        padding-left: 58px;
    }
    #slider > div:nth-child(2),
    #slider > div:nth-child(4),
    #slider > div:nth-child(6),
    #slider > div:nth-child(8),
    #slider > div:nth-child(10),
    #slider > div:nth-child(12),
    #slider > div:nth-child(14),
    #slider > div:nth-child(16),
    #slider > div:nth-child(18)
    {
        padding-right: 58px;
    }
}
.container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .content {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
  }

  .content-tile{
    transform: scale(1);
    z-index: 0;
    transition: all 0.4s ease-in-out 0s;
    overflow: hidden
  }

	.content-button{
    margin-top: 1em;
  }
  
  .content-image{
    width: 100%;
  }

  @media (pointer:coarse) {
    .content-details {
			position: absolute;
			text-align: center;
			padding-left: 1em;
			padding-right: 1em;
			width: 100%;
			top: 50%;
			left: 50%;
			opacity: 1;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}

		.content-details  .content-button {
			position: relative;
			width: fit-content;
			margin: 1em auto;
			text-align: center;
			padding: 0.5em 2em;
		}

		.content .content-overlay {
			background: rgba(0,0,0,0.7);
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0.75;
			-webkit-transition: all 0.4s ease-in-out 0s;
			-moz-transition: all 0.4s ease-in-out 0s;
			transition: all 0.4s ease-in-out 0s;
		}
	}

	@media (pointer:fine) {
		.content-details  .content-title {
			position: absolute;
			text-align: center;
			padding-left: 1em;
			padding-right: 1em;
			width: 100%;
			top: 45%;
			left: 50%;
			opacity: 1;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			-webkit-transition: all 0.3s ease-in-out 0s;
			-moz-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}

		.content:hover .content-details .content-title {
			top: 35%;
		}

		.content-details  .content-text {
			position: absolute;
			text-align: center;
			padding-left: 1em;
			padding-right: 1em;
			width: 60%;
			top: 50%;
			left: 50%;
			opacity: 0;
			-webkit-transform: translate(-50%, -50%) scale(0.75);
			-moz-transform: translate(-50%, -50%) scale(0.75);
			transform: translate(-50%, -50%) scale(0.75);
			-webkit-transition: all 0.3s ease-in-out 0s;
			-moz-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}
		
		.content:hover .content-details .content-text{
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%) scale(1);
			-moz-transform: translate(-50%, -50%) scale(1);
			transform: translate(-50%, -50%) scale(1);
			opacity: 1;
		}

		.content-details .content-button {
			position: absolute;
			text-align: center;
			padding: 0.25em 1em;
			top: 52.5%;
			left: 50%;
			opacity: 1;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			-webkit-transition: all 0.3s ease-in-out 0s;
			-moz-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		}

		.content:hover .content-details .content-button {
			top: 62.5%;
		}

		.content-button:hover {
			border-color: white;
		}

		.content-tile .content{
			transform: scale(1);
			transition: all 0.4s ease-in-out 0s;
		}
	
		.content-tile:hover .content{
			transform: scale(1.15);
			transition: all 0.4s ease-in-out 0s;
		}

		.content .content-overlay {
			background: rgba(0,0,0,0.75);
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0.75;
			-webkit-transition: all 0.4s ease-in-out 0s;
			-moz-transition: all 0.4s ease-in-out 0s;
			transition: all 0.4s ease-in-out 0s;
		}
		
		.content:hover .content-overlay{
			opacity: 0.95;
		}

        .content-button-bar {
            right: -1px;
            width: 0%;
            height: 102%;
            -webkit-transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }
        
        .content-button:hover .content-button-bar {
            width: 102%;
            background-color: white;
        }
        
	}