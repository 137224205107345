.showMenu-enter div{
    opacity: 0;
    transform: translateX(-100%);
    transition: 700ms ease;
}
.showMenu-enter-active div {
    transform: translateX(0%);
    opacity: 1;
}
.showMenu-exit div {
    transform: translateX(0%);
    transition: 700ms ease;
}
.showMenu-exit-active div {
    transform: translateX(-100%);
    transition: 700ms ease;
}
.showMenu-exit-done div{
    transform: translateX(-100%);
    transition: 700ms ease;
}
.showMenu-enter{
    opacity: 0;
    transition: 700ms ease;
}
.showMenu-enter-active {
    opacity: 1;
}
.showMenu-exit {
    opacity: 1;
    transition: 700ms ease;
}
.showMenu-exit-active {
    opacity: 0;
    transition: 700ms ease;
}
.showMenu-exit-done{
    transition: 700ms ease;
}
@media (max-width: 1024px) {
    .appearBackground{
        animation: appearBackground 700ms ease forwards;
    }
    
    @keyframes appearBackground {
        0%{
            background: transparent;
        }100%{
            background: #EEEEEE;
        }
        
    }
}
