.vrDiv{
    animation: vrdiv 1.2s ease forwards;
    position: relative;
}
@keyframes vrdiv {
    0%{
        opacity: 0;
        transform: translateY(150%);
        z-index: 50;
    }100%{
        opacity: 1;
        transform: translateY(2.5%);
        z-index: 10;
    }
}
.vrDivDisappear{
    animation: vrDivDisappear 1.6s ease forwards;
}
@keyframes vrDivDisappear {
    0%{
        opacity: 1;
        top: 88px;
        position: absolute;
        z-index: 10;
    }99%{
        opacity: 0;
        top: 1200px;
        position: absolute;
        z-index: 10;
    }
    100%{
        opacity: 0;
        top: 0px;
        position: absolute;
        z-index: 0;
    }
}
.disappear{
    opacity: 1;
    margin-top: -35%;
    transition: all 1000ms ease;
}
.appearNav{
    opacity: 1;
    animation: appearNav 1000ms ease forwards;
}
@keyframes appearNav {
    0%{
        transform: translateY(-80%);
        z-index: 50;
    }100%{
        transform: translateY(0%);
        z-index: 20;
    }
}
.disappearNav{
    animation: appearNav 1s ease reverse forwards;
}
.aboutContent{
    transform: translateY(0%);
    transition: all 1000ms ease;
}
.aboutContentDisappear{
    transform: translateX(200%);
    display: none;
    opacity: 0;
    position: absolute;
    transition: all 1000ms ease;
}
.landingAppear{
    animation: landingAppear 1s ease forwards;
}
@keyframes landingAppear {
    0%{
        position: relative;
        opacity: 1;
    }100%{
        position: relative;
        opacity: 1;
        z-index: 0;
    }
}
.landingDisappear{
    position: absolute;
    animation: landingDisappear 2000ms forwards;
}
@keyframes landingDisappear {
    0%{
        opacity: 1;
        position: absolute;
        z-index: 20;
    }100%{
        opacity: 0;
        position: absolute;
        z-index:-50;
    }
}
.render-enter #landingFront{
    opacity: 0;
}
.render-enter-done #landingFront{
    opacity: 1;
    transition: all 300ms ease;
}
.render-exit #landingFront{
    opacity: 1;
}
.render-exit-done #landingFront{
    opacity: 0;
    transition: all 700ms ease;
}
.render-enter #vrdiv{
    opacity: 1;
    z-index: 50;
    transform: translateY(100%);
}
.render-enter-done #vrdiv{
    opacity: 1;
    transform: translateY(0%);
    transition: all 1000ms ease;
}
.render-exit-active #vrdiv{
    opacity: 1;
    transform: translateY(0%);
    transition: all 1000ms ease;
}
.render-exit-done #vrdiv{
    opacity: 0;
    transform: translateY(150%);
    transition: all 1000ms ease;
}
@keyframes fade{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

/* 4K AND BIG SCREENS MEDIA QUERY ANIMATIONS */

@media (min-width: 1600px) {
    .vrDiv{
        animation: vrdiv 1.2s ease forwards;
        position: relative;
    }
    #vrImg{
        width: 100vw;
        margin-top: -14px;
    }
    @keyframes vrdiv {
        0%{
            opacity: 0;
            transform: translateY(150%);
            z-index: 50;
        }100%{
            opacity: 1;
            transform: translateY(2.5%);
            z-index: 10;
        }
    }
    .vrDivDisappear{
        animation: vrDivDisappear 1.6s ease forwards;
    }
    @keyframes vrDivDisappear {
        0%{
            opacity: 1;
            top: 88px;
            position: absolute;
            z-index: 10;
        }99%{
            opacity: 0;
            top: 2200px;
            position: absolute;
            z-index: 10;
        }
        100%{
            opacity: 0;
            top: 0px;
            position: absolute;
            z-index: 0;
        }
    }
}
.pixelDivDisappear{
    animation: vrDivDisappear 1.2s ease forwards;
}
@keyframes pixelDivDisappear {
    0%{
        opacity: 1;
        top: 80px;
        position: absolute;
        z-index: 10;
    }100%{
        opacity: 1;
        top: 2200px;
        position: absolute;
        z-index: 0;
    }
}