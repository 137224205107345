@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('SidebarAnimations.css');
@import url('RendererAnimations.css');
@import url('about.css');
@import url('projectsAnimations.css');
@import url('servicesAnimations.css');
@import url('https://fonts.cdnfonts.com/css/menlo');
@import url('https://fonts.cdnfonts.com/css/avenir-lt-std');
@import url('https://unpkg.com/jquery.terminal@2.x.x/css/jquery.terminal.min.css');

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  font-family: Avenir LT Std;
}
.filter {
  background-color: rgba(0, 0, 0, 0.75);
}
.strapline {
  font-family: Menlo;
  font-size: 1.6875rem;
}

.strapline-mini {
  font-family: Menlo;
  font-size: 1.4rem;
}

.icon {
  fill: #fff;
  transition: 200ms ease;
}

.iconMobile {
  fill: #181818;
  transition: 200ms ease;
}

.icon:hover,
.iconMobile:hover {
  fill: #fe4b5a;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonAnimation {
  border-radius: 0;
  text-transform: uppercase;
  font-size: 1.05rem;
  color: black;
  overflow: hidden;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  transition: opacity 700ms ease;
}

.buttonAnimation:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 10px;
  height: 100%;
  background: #FE4B5A;
  z-index: -1;
  -webkit-transition: width 150ms ease-in-out;
  transition: width 150ms ease-in-out;
}

.buttonAnimation:hover {
  color: #fff;
}

.buttonAnimation:hover:after {
  width: 210%;
}

.menuStacks:hover .stack-2 {
  fill: #FE4B5A;
  transform: translateX(20%);
}

.menuStacks:hover .stack-3 {
  transform: translateX(40%);
}

.stack-1.menu-close {
  opacity: 0;
}

.stack-2.menu-close {
  transform: translateY(-0.25rem) rotate(45deg);
}

.stack-3.menu-close {
  transform: translateY(-1rem) rotate(-45deg);
}

.menuStacks:hover .stack-2.menu-close {
  transform: translateY(-0.25rem) rotate(45deg);
  fill: #01b4a5;
}

.menuStacks:hover .stack-3.menu-close {
  transform: translateY(-1rem) rotate(-45deg);
  fill: #01b4a5;
}


.arrowAnimation span {
  font-size: 36px;
  line-height: 40px;
}

.arrowAnimation:hover span {
  color: #FE4B5A;
  transform: scaleX(3) scaleY(2.5) translateY(-5%) translateX(-20%);
  font-weight: 600;
  z-index: 50;
}

.arrowAnimation:hover svg {
  transform: translateX(25%);
}

.socialMedia {
  display: none;
}

.appearSocialMedia {
  animation: appearSocial 600ms ease forwards;
}

.cutAngle {
  clip-path: polygon(0% 100%, calc(100% - 28px) 100%, 100% 0%, 0% 0%, 100% 0%, 0% 0%);
}

@keyframes appearSocial {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.disappearSocialMedia {
  animation: disappearSocial 600ms ease forwards;
}

@keyframes disappearSocial {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.scrollStyle::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: #383636;
}

.circle {
  height: 50px;
  width: 50px;
  display: table-cell;
  text-align: center;
  font-size: 30px;
  vertical-align: middle;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.leftBarRed {
  opacity: 1;
  background-color: #FE4B5A;
  width: 80px;
  transition: all 700ms ease;
}

.leftBarGreen {
  opacity: 1;
  background-color: #5B8172;
  width: 80px;
  transition: all 700ms ease;
}

.svgWhite {
  filter: brightness(10);
}

.terminal {
  --size: 2.5;
}

input:focus,
textarea:focus {
  outline: none;
}

textarea {
  max-height: 20vh;
}

label::first-letter {
  text-transform: capitalize;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.border-animation {
  position: absolute;
  left: 0;
  transition: 200ms ease-in-out;
}

.border-bottom {
  width: 100%;
  height: 3.5px;
  transform: scaleX(0);
  background-color: #fff;
  bottom: -5px;
  transform-origin: bottom middle;
}

.languageSelector:hover .border-bottom {
  transform: scaleX(1);
}

.border-bottom-active {
  width: 100%;
  height: 3.5px;
  transform: scaleX(1);
  background-color: #fff;
  bottom: -5px;
  transform-origin: bottom middle;
}

.fixedCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.transformMiddle {
  transform: translate(-50%);
}

.textShadow {
  text-shadow: 1px 1px 2px #000000
}

.textUnshadowHover:hover {
  text-shadow: none
}

.tab:before,
.tab:after {
  position: absolute;
  bottom: -1px;
  width: 13px;
  height: 13px;
  content: " ";
}

.tab:before {
  left: -12px;
  border-bottom-right-radius: 12px;
  border-width: 0 1px 1px 0;
  box-shadow: 2px 4px 0 #eeeeee;
}

.tab:after {
  right: -12px;
  border-bottom-left-radius: 12px;
  border-width: 0 0 1px 1px;
  box-shadow: -2px 4px 0 #eeeeee;
}

.terminal-wrapper {
  overflow-y: auto;
  max-height: 100%;
}

.invidar-about-button {
  text-align: center;
  padding: 0.15em 1.5em;
  margin-top: 10px;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.invidar-about-button:hover {
	border-color: white;
}

.invidar-about-button-bar {
	right: -1px;
	width: 0%;
  height: 102%;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.invidar-about-button:hover .invidar-about-button-bar {
	width: 102%;
	background-color: white;
}

@media only screen and (min-width: 1024px) {
  .about-cover {
    height: calc(100vh - 260px);
  }
}

.center-vertically {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.center-vertically.rotate-180 {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%) rotate(180deg);
  -moz-transform: translate(0, -50%) rotate(180deg);
  transform: translate(0, -50%) rotate(180deg);
}

.center-fully {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-vertically-fixed {
  position: fixed;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.video-background-iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
}

.service-background {
  background-color: rgba(0, 0, 0, 0.75);
}

.services-back {
  font-size: 1.4rem;
}

/* Desktop */
@media (min-width: 1024px) {
  .team-footer {
    height: 32rem;
  }
}

/* Mobile */
@media (max-width: 1024px) {
  .team-footer {
    height: 15rem;
  }
}
/* 
.enter-right-first {
  transform: translate(20px);
} */

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}

.contactinput + .contactlabel {
  position: absolute;
  top: 12px;
  left: 12px;
  transition: all 500ms ease;
  font-size: 1.3rem;
}

.contactinput:focus + .contactlabel, .contactinput:not(:placeholder-shown) + .contactlabel {
  top: -22px;
  left: 0;
  font-size: 1.1rem;
}

.contacthiddeninput + .contactlabel {
  position: absolute;
  top: 12px;
  left: 12px;
  transition: all 500ms ease;
  font-size: 1.3rem;
}

.contactspaninput:focus + .contacthiddeninput + .contactlabel, .contacthiddeninput:not(:placeholder-shown) + .contactlabel {
  top: -22px;
  left: 0;
  font-size: 1.1rem;
}

.projectContainer .projectCard{
  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.64, 0.57, 0.6, 1.3);
  animation-fill-mode: both;
}

.projectContainer .projectCard:nth-child(1) { animation-delay: .2s; }
.projectContainer .projectCard:nth-child(2) { animation-delay: .4s; }
.projectContainer .projectCard:nth-child(3) { animation-delay: .6s; }
.projectContainer .projectCard:nth-child(4) { animation-delay: .8s; }
.projectContainer .projectCard:nth-child(5) { animation-delay: 1.0s; }
.projectContainer .projectCard:nth-child(6) { animation-delay: 1.2s; }
.projectContainer .projectCard:nth-child(7) { animation-delay: 1.4s; }
.projectContainer .projectCard:nth-child(8) { animation-delay: 1.6s; }
.projectContainer .projectCard:nth-child(9) { animation-delay: 1.8s; }
.projectContainer .projectCard:nth-child(10) { animation-delay: 2.0s; }
.projectContainer .projectCard:nth-child(11) { animation-delay: 2.2s; }
.projectContainer .projectCard:nth-child(12) { animation-delay: 2.4s; }

.service-background{
  position: absolute;
  left: 0;
  animation-duration: 0.75s;
  animation-name: animate-slidein-left;
  animation-timing-function: cubic-bezier(0.64, 0.57, 0.6, 1);
  animation-fill-mode: both;
}

.contactform-wrapper{
  height: fit-content;
  right: 0;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-name: animate-slidein-right;
  animation-timing-function: cubic-bezier(0.64, 0.57, 0.6, 1);
  animation-fill-mode: both;
}

/* .contact-map{
  position: absolute;
  bottom: 0;
  animation-duration: 0.75s;
  animation-name: animate-slidein-bottom;
  animation-timing-function: cubic-bezier(0.64, 0.57, 0.6, 1);
  animation-fill-mode: both;
} */

#typed span {
  color: #FE4B5A;
}

.dynamic-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.dynamic-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.dynamic-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.dynamic-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.dynamic-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.dynamic-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.dynamic-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.dynamic-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.dynamic-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.dynamic-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.dynamic-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.dynamic-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

@media (max-width: 1024px) {
  .dynamic-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-5 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-6 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-7 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-8 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-9 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-10 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-11 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .dynamic-cols-12 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .dynamic-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-5 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-6 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-7 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-8 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-9 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-10 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-11 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dynamic-cols-12 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@keyframes animate-pop {
  0% {
    transform: scale(0, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes animate-slidein-left {
  0% {
    left: -120%;
  }

  100% {
    left: 0;
  }
}

@keyframes animate-slidein-right {
  0% {
    right: -120%;
  }

  100% {
    right: 0;
  }
}

@keyframes animate-slidein-bottom {
  0% {
    bottom: -120%;
  }

  100% {
    bottom: 0;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .projectContainer .projectCard { animation: none !important; }
  .service-background { animation: none !important; }
  .contactform-wrapper { animation: none !important; }
  .contact-map { animation: none !important; }
}
.terms {
  h1 {
    font-size: 1.4rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  h2 {
    font-size: 1.2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  h3 {
    font-size: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  h4 {
    font-size: 0.9rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  p{
    font-size: 0.8rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
}