/* Desktop */
@media (min-width: 1024px) {
	.service-content-details  .service-content-title {
		position: absolute;
		text-align: center;
		padding-left: 1em;
		padding-right: 1em;
		top: 45%;
		left: 50%;
		opacity: 1;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-content-details .service-content-button {
		position: absolute;
		text-align: center;
		padding: 0.25em 1em;
		top: 52.5%;
		left: 50%;
		opacity: 1;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-content-details  .service-content-text {
		position: absolute;
		text-align: center;
		padding-left: 1em;
		padding-right: 1em;
		width: 24rem;
		top: 50%;
		left: 50%;
		opacity: 0;
		-webkit-transform: translate(-50%, -50%) scale(0.75);
		-moz-transform: translate(-50%, -50%) scale(0.75);
		transform: translate(-50%, -50%) scale(0.75);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-segment:hover .service-content-details .service-content-title {
		top: 32.5%;
	}

	.service-segment:hover .service-content-details .service-content-button {
		top: 67.5%;
	}

	.service-segment:hover .service-content-details .service-content-text{
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%) scale(1);
		-moz-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
}

/* Mobile */
@media (max-width: 1024px) {
	.service-content-details  .service-content-title {
		position: absolute;
		text-align: center;
		padding-left: 1em;
		padding-right: 1em;
		top: 35%;
		margin-bottom: 20px;
		left: 50%;
		opacity: 1;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-content-details .service-content-button {
		position: absolute;
		text-align: center;
		padding: 0.15em 1.5em;
		top: 60%;
		margin-top: 10px;
		left: 50%;
		opacity: 1;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-content-details  .service-content-text {
		position: absolute;
		text-align: center;
		padding-left: 1em;
		padding-right: 1em;
		width: 90%;
		top: 50%;
		left: 50%;
		opacity: 0;
		-webkit-transform: translate(-50%, -50%) scale(0.75);
		-moz-transform: translate(-50%, -50%) scale(0.75);
		transform: translate(-50%, -50%) scale(0.75);
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	}

	.service-segment:hover .service-content-details .service-content-title {
		top: 10%;
	}

	.service-segment:hover .service-content-details .service-content-button {
		top: 90%;
	}

	.service-segment:hover .service-content-details .service-content-text{
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%) scale(1);
		-moz-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
}

.service-content-button:hover {
	border-color: white;
}

.service-content-button-bar {
	right: -1px;
	width: 0%;
	height: 102%;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.service-content-button:hover .service-content-button-bar {
	width: 102%;
	background-color: white;
}

.service-content-tile .service-content{
	transform: scale(1);
	transition: all 0.4s ease-in-out 0s;
}

.service-content-tile:hover .service-content{
	transform: scale(1.15);
	transition: all 0.4s ease-in-out 0s;
}

.service-segment .service-content-overlay {
	background: rgba(0,0,0,0.75);
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0.75;
	-webkit-transition: opacity 0.4s ease-in-out 0s;
	-moz-transition: opacity 0.4s ease-in-out 0s;
	transition: opacity 0.4s ease-in-out 0s;
}

.service-segment:hover .service-content-overlay{
	opacity: 0.95;
}